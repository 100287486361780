.head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.head__item {
  flex-basis: 100%;
}

.head__item:first-child {
  flex-basis: 30%;
}

.head__item:nth-child(2) {
  flex-basis: calc(70% - 40px);
  margin-left: 40px;
}

.head__avatar {
  max-width: 320px;
  border-radius: 50%;
}


.head__btn {
  margin-top: 20px;
}
// head-txt
.head-txt {
}
.head-txt__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head-txt__num {
  flex-basis: 33.333%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.head-txt__body {
  margin-top: 40px;
  font-size: 18px;
  line-height: 1.5;
}

.head-txt__txt:not(:first-child) {
  margin-top: 15px;
}

.head-txt__link {
  text-decoration: none;
  color: #333;
}



// head-tabs

.head-tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.head-tabs__btn {
  flex-basis: 33.333%;
  border-bottom: 2px solid #333;
  transition: all 0.3s;
}

.head-tabs__btn:hover {
  background-color: rgba(204, 204, 204, 0.203);
}

.head-tabs__btn:not(:first-child) {
  margin-left: 5px;
}

.head-tabs__btn_active {
  border-bottom: 4px solid #333;
}

.head-tabs__icon {
  display: block;
  max-width: 50px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .head-txt__body {
    margin-top: 20px;
  }
}

@media (max-width: 425px) {
  .head__item:first-child {
    flex-basis: 40%;
  }

  .head__item:nth-child(2) {
    flex-basis: calc(60% - 10px);
    margin-left: 10px;
  }

  .head-txt__num {
    font-size: 16px;
    line-height: 1.5;
  }
  .head-txt__body {
    font-size: 14px;
  }
}
