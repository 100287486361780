@import "modules/header/header";

@import "modules/head/head";

@import "modules/main-gallery/main-gallery";

@import "modules/main-second/main-second";

@import "modules/sliders/sliders";

@import "modules/footer/footer.scss";

@import "modules/popups/popups.scss";

.main {
  padding-top: 120px;
}

.btn {
  display: block;
  padding: 15px 5px;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  background-color: rgba(204, 204, 204, 0.777);
  transition: all 0.3s;
  border: none;
}

.btn:hover {
  background-color: #ccc;
}

@media (max-width: 425px) {
  .btn {
    padding: 10px 5px;
    font-size: 18px;
  }
}
