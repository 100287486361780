.swiper {
  width: 100%;
  min-height: 600px;
  max-height: 900px;
  height: auto;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  background-color: #fff;
}

.swiper-pagination-bullet {
  background-color: #333;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: 50% 50%;
}

@media (max-width: 1024px) {
  .swiper {
    min-height: 500px;
  }
}

@media (max-width: 768px) {
  .swiper {
    min-height: 400px;
  }
}

@media (max-width: 425px) {
    .swiper {
      min-height: 300px;
    }
  }