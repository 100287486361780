.header-container {
  position: relative;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  text-align: center;
  background-image: url("../img/header_bg.png");
  background-size: contain;

  &__body {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 5px;
  }

  &__logo {
    width: 100%;

  }

  &__logo-img {
    margin-right: auto;
    width: 100%;
    max-width: 640px;
    display: block;
    object-fit: contain;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
  }

  &__link {
    height: 100%;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.45s;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.7);
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      color: #333;
    }
  }

  &__burger {
    display: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      span {
        background-color: #333;
      }

      span:nth-child(2) {
        transition: all 0.3s;
        transform: translateX(14px);
      }
    }
  }
}

@media (max-width: 425px) {
  .header__link {
    font-size: 20px;
  }

  .header__burger {
    width: 40px;
  }
}

.header__list {
  position: fixed;
  z-index: -1;
  z-index: 100;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  top: 0;
  right: -100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header__menu {
  order: 1;
}

.header__burger {
  order: 3;
  transform: translateY(-4px);
}
.main-menu {
  position: relative;

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li:not(:first-child) {
      margin-top: 40px;
    }
  }
}

.menu_active {
  left: 30%;
  top: 70px;
  z-index: -1;
  background-color: #ccc;
  background-image: url(../img/header_bg.png);
  background-size: 60px 60px;
  overflow-y: scroll;
}

.header__burger {
  display: block;
  z-index: 1100;
  width: 50px;
}

.header__burger span {
  display: block;
  height: 4px;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.795);
  border-radius: 2px;
  transition: all 0.3s;
}

.header__burger span:not(first-child) {
  margin-top: 8px;
}

.header__burger span:nth-child(2) {
  width: 70%;
  display: block;
}

.burger_active {
  height: 30px;
  margin-top: 10px;
}

.burger_active span {
  transition: all 0.3s;
}

.burger_active span:nth-child(1) {
  transform: rotate(-45deg);
  margin-bottom: -10px;
}

.burger_active span:nth-child(2) {
  display: none;
}

.burger_active span:nth-child(3) {
  transform: rotate(45deg);
  margin-bottom: 20px;
}

.link-hover {
  position: relative;
  display: block;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    bottom: -4px;
    background-color: #333;
    transform: scale(0);
    transition: all 0.25s;
  }

  &:hover {
    cursor: pointer;

    &::after {
      transform: scale(1);
    }
  }
}

@media (max-width: 620px) {
  .main-menu {
    &__list {
      li:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .submenu {
    margin-top: 15px;
  }


}

.header__link_submenu_wrap a {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.45s;
  text-transform: uppercase;
  color: rgba(51, 51, 51, 0.7);
  transition: all 0.3s;
}

.header__link_submenu_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.header__link_submenu_wrap a:hover {
  color: #333;
}

.submenu__arrow-wrap {
  margin-left: 15px;
  margin-top: 6px;
}

.submenu {
  display: none;
  text-align: left;
  margin-top: 30px;
}

.submenu__arrow-wrap svg {
  transform: rotate(180deg);
  max-width: 10px;
  fill: #333;
}
