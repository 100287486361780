*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch !important;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

body::-webkit-scrollbar:vertical {
  width: 4px;
}

body::-webkit-scrollbar:horizontal {
  height: 4px;
}

body::-webkit-scrollbar-thumb {
  background-color: #939ebd;
  opacity: 0.5;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(42, 54, 92, 0.7);
}

html,
body {
  box-sizing: border-box;
  color: #333;
}

ul {
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s;
}

img[data-src] {
  opacity: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
  z-index: 5;
  max-width: 970px;
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
}

.main {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

@media (max-width: 550px) {
  .container {
    padding: 0 5px;
  }
  .main {
    padding-top: 80px;
  }
}
