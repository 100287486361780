html,
body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #fff;
}

.main-h2 {
   position: relative;
   z-index: 5;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 123.5%;


    color: #FFFFFF;
}

